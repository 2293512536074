import React, {useEffect, useState} from 'react';
import {Routes, Route, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import update from 'immutability-helper';
import Home from './Home';
import Editor from './Editor';
import { Box } from '@mui/material';
import Guid from './Guid';
import Day from './Day';
import Leg from './Leg';
import ProtectedRoute from "./ProtectedRoute";
import SignIn from "./SignIn";
import MapBoxEditor from "./MapboxEditor";

export type User = {
  username: string,
  password: string,
  routeID: number
}

function App() {
  const ENDPOINT = process.env["REACT_APP_ENDPOINT "] ?? 'https://srv.raduni-app.it/raduno-demo-ws/service/portal/route';
  const [fullEndpoint, setFullEndpoint] = useState<string>(ENDPOINT);
  const [days, setDays] = useState<Day[]>([{ id: Guid.newGuid(), title: "DAY 1", legs: [{ id: Guid.newGuid(), title: "Leg 1", route: [], kmlCoordinates: [], editMode: false, isKml: false, tolerance: 600 }], editMode: false }]);
  const [user, setUser] = React.useState<User | undefined>(undefined);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleLogin = (user: User) => {
    if (user.username === 'demo' && user.password === 'Nm5zMzI5MXYyZQ==') {
      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
      navigate('home', {replace: true});
    }else {
      setUser(undefined);
      localStorage.clear();
    }
  };

  useEffect(() => {
    const token = searchParams.get("token");
    const item = localStorage.getItem('user');

    if (token) {
      const decoded = jwtDecode<User>(token);
      if (decoded) {
        if (decoded.routeID) {
          setFullEndpoint(ENDPOINT + '/' + decoded.routeID);
        }else {
          setFullEndpoint(ENDPOINT);
        }

        handleLogin(decoded);
      }
    } else if (item) {
      const user = JSON.parse(item);
      if (user.routeID) {
        setFullEndpoint(ENDPOINT + '/' + user.routeID);
      }
      if (user) {
        setUser(user as User);
      }
    }
  }, []);

  const updateDays = async (days: Day[]) => {
    setDays(days);
    await saveDataToServer(days);
  }
  const addDay = async (title: string) => {
    const updatedDays = update(days, { $push: [{ id: Guid.newGuid(), title, legs: [{ id: Guid.newGuid(), title: "Leg 1", route: [], kmlCoordinates: [], editMode: false, isKml: false, tolerance: 600 }], editMode: false }] })  as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }
  
  const addDayWithData = async (day: Day) => {
    const updatedDays = update(days, { $push: [day]});
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const deleteDay = async (id: string) => {
    let updatedDays = days.filter((d, _) => d.id !== id) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const addLeg = async (dayId: string) => {
    let dayIndex = days.findIndex(d => d.id === dayId);
    const legCount = days[dayIndex].legs.length;
    const updatedDays = update(days, { [dayIndex]: { legs: { $push: [{ id: Guid.newGuid(), title: `Leg ${legCount + 1}`, route: [], kmlCoordinates: [], editMode: true, isKml: false, tolerance: 600 }] } } }) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const deleteLeg = async (dayId: string, legId: string) => {
    let dayIndex = days.findIndex(d => d.id === dayId);
    let legIndex = days[dayIndex].legs.findIndex(l => l.id === legId);
    const updatedDays = update(days, { [dayIndex]: { legs: { $splice: [[legIndex, 1]] } } }) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const editDayTitle = async (id: string) => {
    let index = days.findIndex(d => d.id === id);
    const updatedDays = update(days, { [index]: { editMode: { $set: true } } }) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const editLegTitle = async (dayId: string, legId: string) => {
    let dayIndex = days.findIndex(d => d.id === dayId);
    let legIndex = days[dayIndex].legs.findIndex(l => l.id === legId);
    const updatedDays = update(days, { [dayIndex]: { legs: { [legIndex]: { editMode: { $set: true } } } } }) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const updateDayTitle = async (id: string, newTitle: string) => {
    let index = days.findIndex(d => d.id === id);
    const updatedDays = update(days, { [index]: { title: { $set: newTitle }, editMode: { $set: false } } }) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const updateLegTitle = async (dayId: string, legId: string, newTitle: string) => {
    let dayIndex = days.findIndex(d => d.id === dayId);
    let legIndex = days[dayIndex].legs.findIndex(l => l.id === legId);
    const updatedDays = update(days, { [dayIndex]: { legs: { [legIndex]: { title: { $set: newTitle }, editMode: { $set: false } } } } }) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  const saveDataToServer = async (updatedDays: Day[]) => {
    const exportData = updatedDays.map(d => {
      return {
        title: d.title,
        day: d.title,
        simulated: false,
        item: d.legs.map(l => {
          return {
            time: "",
            title: l.title,
            titleShort: l.title,
            description: "",
            name: "",
            location: "",
            website: "",
            phone: "",
            mail: "",
            lat: 0,
            lng: 0,
            isKml: l.isKml,
            kmlCoordinates: l.kmlCoordinates,
            tolerance: l.tolerance,
            route: l.route.map(r => {
              return {
                type: r.type,
                lat: r.coordinates.lat,
                lng: r.coordinates.lng,
              }
            })
          }
        })
      }
    })

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa('appuser:JqmaNfTJz4wapTh38nkqnt5XejcVN5')
      },
      body: JSON.stringify(exportData)
    };
    
    const response = await fetch(fullEndpoint, requestOptions);
    if (response.ok) {
      const isJson = response.headers.get('content-type')?.includes('application/json');
      const data = isJson && await response.json();
      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
    }
  }

  async function updateLeg(dayId: string, leg: Leg) {
    if (days.length === 0) return;
    let dayIndex = days.findIndex(d => d.id === dayId);
    if (!days[dayIndex] || !days[dayIndex].legs || days[dayIndex].legs.length === 0) return;
    let legIndex = days[dayIndex].legs.findIndex(l => l.id === leg.id);
    const updatedDays = update(days, { [dayIndex]: { legs: { [legIndex]: { $set: leg } } } }) as Day[];
    setDays(updatedDays);
    await saveDataToServer(updatedDays);
  }

  return (
      <Box>
        <Routes>
          <Route index element={<SignIn user={user} handleSignIn={handleLogin} />} />
          <Route path={"signin"} element={<SignIn user={user} handleSignIn={handleLogin} />} />
          <Route
              path="home"
              element={
                <ProtectedRoute user={user}>
                  <Home
                      days={days}
                      setDays={setDays}
                      updateDays={updateDays}
                      addDay={addDay}
                      addDayWithData={addDayWithData}
                      addLeg={addLeg}
                      deleteDay={deleteDay}
                      deleteLeg={deleteLeg}
                      editDayTitle={editDayTitle}
                      editLegTitle={editLegTitle}
                      updateDayTitle={updateDayTitle}
                      updateLegTitle={updateLegTitle}
                      endpoint={fullEndpoint}
                  />
                </ProtectedRoute>
              }
          />
          <Route
              path="editor"
              element={
                <ProtectedRoute user={user}>
                  <Editor updateLeg={updateLeg}/>
                </ProtectedRoute>
              }
          />
          <Route
              path="map"
              element={
                <ProtectedRoute user={user}>
                  <MapBoxEditor updateLeg={updateLeg}/>
                </ProtectedRoute>
              }
          />
        </Routes>
      </Box>
  );
}

export default App;
