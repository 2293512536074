import JSZip from "jszip";
import Day from "./Day";
import Guid from "./Guid";
import Leg from "./Leg";
import Waypoint from "./Waypoint";
import WaypointType from "./WaypointType";
import {StringBuilder} from "./StringBuilder";

export async function unzipKmz(file: File): Promise<string | undefined> {
    try {
        const jszip = new JSZip();
        const content = await jszip.loadAsync(file);

        const kmlFile = content.file("doc.kml");
        if (kmlFile) {
            return await kmlFile.async("text");
        } else {
            return undefined;
        }
    } catch (error) {
        console.error("Error unzipping file:", error);
        return undefined;
    }
}

export function parseKml(plainText: string, tolerance: number): Day {
    let parser = new DOMParser()
    let xmlDoc = parser.parseFromString(plainText, "text/xml")

    if (xmlDoc.documentElement.nodeName !== "kml") { 
        throw "unsupported file";
    }

    return {
        id: Guid.newGuid(),
        title: getDayTitle(xmlDoc),
        legs: parseLegs(xmlDoc, tolerance),
        editMode: false
    };
}

function getDayTitle(xmlDoc: Document) {
    const documentNode = xmlDoc.documentElement.getElementsByTagName('Document')[0];
    const title = documentNode.getElementsByTagName('name')[0].childNodes[0].nodeValue;
    return title ?? 'DAY';
}

function parseLegs(xmlDoc: Document, tolerance: number): Leg[] {
    const legs: Leg[] = [];

    for (const leg of xmlDoc.getElementsByTagName('Folder') as any) {
        const lineStringsCount = leg.getElementsByTagName('LineString').length;
        if (lineStringsCount == 0) continue;
        
        let legTitle = leg.getElementsByTagName('name')[0].childNodes[0].nodeValue.trim();
        legs.push({
            id: Guid.newGuid(),
            title: legTitle,
            kmlCoordinates: parseKmlCoordinates(leg),
            route: parseRoute(leg, tolerance),
            editMode: false,
            isKml: true,
            tolerance: tolerance
        })
    }
    
    return legs;
}

function parseRoute(leg: any, tolerance: number): Waypoint[] {
    const route: Waypoint[] = [];
    for (const item of leg.getElementsByTagName('Placemark') as any) {
        let lineStrings = item.getElementsByTagName('LineString');
        for (const line of lineStrings) {
            let coordinatesString = line.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
            let points = coordinatesString.split("\n")

            for (const point of points) {
                let coordinate = point.split(",")
                route.push({
                    id: Guid.newGuid(),
                    type: WaypointType.Waypoint,
                    coordinates: {lat: +coordinate[1], lng: +coordinate[0]},
                })
            }
        }
    }
    
    return optimizeRoute(route, tolerance);
}

function parseKmlCoordinates(leg: any): google.maps.LatLngLiteral[] {
    const result: google.maps.LatLngLiteral[] = [];
    for (const item of leg.getElementsByTagName('Placemark') as any) {
        let lineStrings = item.getElementsByTagName('LineString');
        for (const line of lineStrings) {
            let coordinatesString = line.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
            let points = coordinatesString.split("\n")

            for (const point of points) {
                let coordinate = point.split(",")
                result.push({
                    lat: +coordinate[1],
                    lng: +coordinate[0],
                })
            }
        }
    }

    return result;
}

function getPerpendicularDistance(point: google.maps.LatLngLiteral, lineStart: google.maps.LatLngLiteral, lineEnd: google.maps.LatLngLiteral): number {
    const area = Math.abs(0.5 * (lineStart.lat * lineEnd.lng + lineEnd.lat * point.lng + point.lat * lineStart.lng - lineEnd.lat * lineStart.lng - point.lat * lineEnd.lng - lineStart.lat * point.lng));
    const bottom = Math.sqrt(Math.pow(lineStart.lat - lineEnd.lat, 2) + Math.pow(lineStart.lng - lineEnd.lng, 2));
    return (area / bottom * 2);
}

function ramerDouglasPeucker(points: google.maps.LatLngLiteral[], epsilon: number): google.maps.LatLngLiteral[] {
    if (points.length < 2) {
        return points;
    }

    let dmax = 0;
    let index = 0;
    const end = points.length - 1;

    for (let i = 1; i < end; i++) {
        const d = getPerpendicularDistance(points[i], points[0], points[end]);
        if (d > dmax) {
            index = i;
            dmax = d;
        }
    }

    let result: google.maps.LatLngLiteral[] = [];

    if (dmax > epsilon) {
        const recResults1 = ramerDouglasPeucker(points.slice(0, index + 1), epsilon);
        const recResults2 = ramerDouglasPeucker(points.slice(index, end + 1), epsilon);

        result = recResults1.slice(0, recResults1.length - 1).concat(recResults2);
    } else {
        result = [points[0], points[end]];
    }

    return result;
}


export function optimizeRoute(waypointsSource: Waypoint[], tolerance: number): Waypoint[] {
    console.log(`Starting the optimizeRoute algorithm with ${waypointsSource.length} waypoints and a tolerance of ${tolerance}.`);

    let waypoints = cleanUpWaypointsThatAreTooCloseToEachOther(waypointsSource, 10);
    if (waypoints.length <= 0) {
        return waypoints;
    }

    console.log(`Cleaned up waypointsSource: ${waypointsSource.length} down to ${waypoints.length}, because they were too close to each other.`);
    if (waypoints.length < tolerance) {
        console.log(`No optimization needed. Got ${waypoints.length} waypoints.`);
        waypoints[0].type = WaypointType.Start;
        waypoints[waypoints.length - 1].type = WaypointType.End;
        return waypoints;
    }
    
    let epsilon = 0.00001; 
    let coordinates = waypoints.map(wp => wp.coordinates);
    let optimizedCoordinates = ramerDouglasPeucker(coordinates, epsilon);

    while (optimizedCoordinates.length > tolerance) {
        // console.log(`Increasing the epsilon value because there are still ${optimizedCoordinates.length} coordinates in the list.`)
        epsilon += 0.00002;
        optimizedCoordinates = ramerDouglasPeucker(coordinates, epsilon);
    }

    console.log(`Concluding the optimizeRoute algorithm with ${optimizedCoordinates.length} waypoints.`);
    const result = waypoints.filter(wp => optimizedCoordinates.some(coord => coord.lat === wp.coordinates.lat && coord.lng === wp.coordinates.lng));
    result[0].type = WaypointType.Start;
    result[result.length - 1].type = WaypointType.End;
    // console.log(createGpx(result));
    return result;
}

function cleanUpWaypointsThatAreTooCloseToEachOther(waypoints: Waypoint[], thresholdMeters: number): Waypoint[] {
    if (waypoints.length === 0) return [];

    const cleanedWaypoints: Waypoint[] = [waypoints[0]]; 

    for (let i = 1; i < waypoints.length; i++) {
        const lastSavedWaypoint = cleanedWaypoints[cleanedWaypoints.length - 1];
        const currentWaypoint = waypoints[i];

        if (calculateDistanceBetweenTwoWayoints(lastSavedWaypoint, currentWaypoint) > thresholdMeters) {
            cleanedWaypoints.push(currentWaypoint);
        }
    }

    return cleanedWaypoints;
}

function calculateDistanceBetweenTwoWayoints(wp1: Waypoint, wp2: Waypoint): number {
    const earthRadius = 6371000; // Earth's radius in meters
    const lat1 = wp1.coordinates.lat * Math.PI / 180;
    const lon1 = wp1.coordinates.lng * Math.PI / 180;
    const lat2 = wp2.coordinates.lat * Math.PI / 180;
    const lon2 = wp2.coordinates.lng * Math.PI / 180;

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return earthRadius * c; // distance in meters
}

function createGpx(waypoints: Waypoint[]): string {
    const gpxBuilder = new StringBuilder();

    // Start GPX file
    gpxBuilder.append(`<?xml version="1.0" encoding="UTF-8" standalone="no" ?>\n`);
    gpxBuilder.append(`<gpx version="1.1" creator="MyApp" xmlns="http://www.topografix.com/GPX/1/1">\n`);
    gpxBuilder.append(`  <metadata>\n`);
    gpxBuilder.append(`    <name>RoutingTool GPX</name>\n`);
    gpxBuilder.append(`  </metadata>\n\n`);

    // Add each waypoint
    waypoints.forEach(({ coordinates }) => {
        gpxBuilder.append(`  <wpt lat="${coordinates.lat}" lon="${coordinates.lng}"></wpt>\n`);
    });

    // End GPX file
    gpxBuilder.append(`</gpx>`);

    return gpxBuilder.toString();
}

