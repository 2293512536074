// @ts-nocheck
import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Waypoint from "./Waypoint";
import {useLocation, useNavigate} from "react-router-dom";
import Typography from "@mui/material/Typography";
import startSvg from './Arrivo.svg';
import endSvg from './Partenza.svg';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from "@mui/material/Box";
import {Button, CircularProgress, Collapse, Slider} from "@mui/material";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Leg from "./Leg";
import {optimizeRoute} from "./KmlImporter";
import WaypointType from "./WaypointType";

type EditorProps = {
    updateLeg: (dayId: string, leg: Leg) => Promise<void>,
};

type EditorState = {
    dayId: string,
    leg: Leg
}
const MapBoxEditor = ({ updateLeg }: EditorProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const mapRef = useRef()
    const mapContainerRef = useRef()
    const [legTitle, setLegTitle] = useState("");
    const [tolerance, setTolerance] = useState<number>(600);
    const [appliedTolerance, setAppliedTolerance] = useState<number>(600);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
    const accessToken = 'pk.eyJ1IjoicmFkdW5pYXBwIiwiYSI6ImNscmo4c3VuajAwemMya2swZGYxOXJ1NjMifQ.2kCbnBGKIynyls_YE13Mmw';

    useEffect(() => {
        if (!location.state) {
            navigate("/");
            return;
        }

        mapboxgl.accessToken = accessToken;
        const locationState = location.state;
        setLegTitle(locationState.leg.title);
        setLoading(true);
        if (locationState.leg.route && locationState.leg.route.length > 0) {
            setWaypoints(locationState.leg.route);
            console.log('Setting both tolerances to: ' + locationState.leg.tolerance);
            setTolerance(locationState.leg.tolerance);
            setAppliedTolerance(locationState.leg.tolerance);
            let center = [locationState.leg.route[0].coordinates.lng, locationState.leg.route[0].coordinates.lat];
            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                center: center,
                zoom: 12
            });

            const bounds = calculateBounds(locationState.leg.route);
            mapRef.current.fitBounds([
                bounds.southwest,
                bounds.northeast
            ], {animate: false, padding: 100});

            mapRef.current.on("load", () => {
                mapRef.current.addSource("route", {
                    type: "geojson",
                    data: waypointsToGeoJsonLineString(locationState.leg.kmlCoordinates),
                });

                mapRef.current.addLayer({
                    id: "route-line",
                    type: "line",
                    source: "route",
                    paint: {
                        "line-color": "#1976d2",
                        "line-width": 6,
                    },
                })

                addSvgMarker(locationState.leg.route[0].coordinates.lng, locationState.leg.route[0].coordinates.lat, `url(${startSvg})`);
                addSvgMarker(locationState.leg.route[locationState.leg.route.length - 1].coordinates.lng, locationState.leg.route[locationState.leg.route.length - 1].coordinates.lat, `url(${endSvg})`);
                handleApply(locationState.leg.tolerance);
            });
        }

        return () => {
            mapRef.current.remove()
        }
    }, [location]);

    //Use this to clear the sessionStorage of the react router state property, so the user is forwarded to the home page
    //If you do not do this, the user might work for hours on the map tool only to realize after clicking on the save button
    //that nothing was saved.
    useEffect(() => {
        window.history.replaceState({}, document.title)
    }, [])

    function addSvgMarker(longitude: number, latitude: number, svgUrl: string) {
        const el = document.createElement('div');
        el.className = 'custom-svg-marker';
        el.style.width = '40px';
        el.style.height = '48px';
        el.style.backgroundImage = svgUrl;
        el.style.backgroundSize = '100%';
        el.style.backgroundRepeat = 'no-repeat';

        const markerOffset = [20, -20];
        new mapboxgl.Marker(el)
            .setLngLat([longitude, latitude])
            .setOffset(markerOffset)
            .addTo(mapRef.current);
    }

    function calculateBounds(waypoints: Waypoint[]): Bounds {
        if (waypoints.length === 0) {
            throw new Error("Waypoint list is empty.");
        }

        let minLat = Infinity;
        let minLng = Infinity;
        let maxLat = -Infinity;
        let maxLng = -Infinity;

        waypoints.forEach(({ coordinates }) => {
            minLat = Math.min(minLat, coordinates.lat);
            minLng = Math.min(minLng, coordinates.lng);
            maxLat = Math.max(maxLat, coordinates.lat);
            maxLng = Math.max(maxLng, coordinates.lng);
        });

        const southwest: google.maps.LatLngLiteral = { lat: minLat, lng: minLng };
        const northeast: google.maps.LatLngLiteral = { lat: maxLat, lng: maxLng };

        return { southwest, northeast };
    }

    function waypointsToGeoJsonLineString(waypoints: google.maps.LatLngLiteral[]): any {
        const coordinates = waypoints.map((waypoint) => [waypoint.lng, waypoint.lat]);

        const geoJsonLineString = {
            type: "Feature",
            geometry: {
                type: "LineString",
                coordinates: coordinates,
            },
            properties: {
            }
        };

        return geoJsonLineString;
    }

    async function mapMatchingRequest(coordinates: Array<[number, number]>): Promise<any> {
        const MAX_COORDINATES = 100;
        const chunks: Array<Array<[number, number]>> = [];

        for (let i = 0; i < coordinates.length; i += MAX_COORDINATES) {
            chunks.push(coordinates.slice(i, i + MAX_COORDINATES));
        }

        const createUrl = (coords: Array<[number, number]>) => {
            const coordinatesString = coords.map(coord => coord.join(',')).join(';');
            const coordinateAccuracyString = Array(coords.length).fill(25).join(';');

            return `https://api.mapbox.com/matching/v5/mapbox/driving/${coordinatesString}` +
                `?access_token=${accessToken}` +
                `&radiuses=${coordinateAccuracyString}` +
                `&geometries=geojson` +
                `&tidy=true` +
                `&ignore=access,oneways,restrictions`;
        };

        const responses = await Promise.all(
            chunks.map(async (chunk) => {
                const url = createUrl(chunk);
                try {
                    const response = await fetch(url, { method: 'GET' });
                    if (!response.ok) {
                        throw new Error(`Error ${response.status}: ${response.statusText}`);
                    }
                    return response.json();
                } catch (error) {
                    console.error('Error in Map Matching API request:', error);
                    return null; // Handle or skip failed requests as needed
                }
            })
        );

        // const mergedGeometry = {
        //     type: "LineString",
        //     coordinates: responses
        //         .filter(response => response !== null) // Ensure only valid responses are merged
        //         .flatMap(response => response.matchings[0].geometry.coordinates)
        // };

        const mergedGeometry = {
            type: "LineString",
            coordinates: responses
                .filter(response => response !== null) // Ensure only valid responses are merged
                .flatMap(response => getMatchingWithMaxDistance(response.matchings).geometry.coordinates)
        };

        return mergedGeometry;
    }

    function displayMatchedRouteOnMap(matchedData: any) {
        // Check if the source already exists and remove it if so
        if (mapRef.current.getSource('matched-route')) {
            mapRef.current.removeLayer('matched-route');
            mapRef.current.removeSource('matched-route');
        }

        // Add the matched route as a new source
        mapRef.current.addSource('matched-route', {
            type: 'geojson',
            data: matchedData
        });

        // Add a new layer to visualize the route
        mapRef.current.addLayer({
            id: 'matched-route',
            type: 'line',
            source: 'matched-route',
            layout: {
                'line-join': 'round',
                'line-cap': 'round'
            },
            paint: {
                'line-color': '#F44336', // Red line color
                'line-width': 3 // Line width
            }
        });
    }

    const handleToggle = () => {
        setExpanded((prev) => !prev);
    };

    const handleApply = async (realTolerance: number) => {
        const startTime = Date.now();
        setLoading(true);
        const locationState = location.state as EditorState;
        const kmlCoordinates = locationState.leg.kmlCoordinates;
        // Step 1: Get optimized waypoints from the current tolerance settings and the kml coordinates
        const optimizedWaypoints = optimizeRoute(kmlCoordinates.map(c => ({
            type: WaypointType.Waypoint,
            coordinates: {lat: c.lat, lng: c.lng}
        })), realTolerance);

        // Step 2: Call the mapbox matching api with the newly optimized waypoints
        const responseData = await mapMatchingRequest(optimizedWaypoints.map(waypoint => [waypoint.coordinates.lng, waypoint.coordinates.lat]));
        // Step 3: Parse the result of the matching api and render it on the map
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime < 300) {
            const remainingTime = 300 - elapsedTime;
            setTimeout(() => {
                displayMatchedRouteOnMap(responseData);
                // Step 4: Save the optimized waypoints to the waypoints state so they get saved when pressing the save button
                setWaypoints(optimizedWaypoints);
                setAppliedTolerance(realTolerance);
                setLoading(false);

            }, remainingTime);
        } else {
            displayMatchedRouteOnMap(responseData);
            // Step 4: Save the optimized waypoints to the waypoints state so they get saved when pressing the save button
            setWaypoints(optimizedWaypoints);
            setAppliedTolerance(realTolerance);
            setLoading(false);
        }
    };
    function getHighestConfidenceEntry(matchings: Matching[]): Matching | undefined {
        if (matchings.length === 0) return undefined;

        return matchings.reduce((max, current) => {
            return current.confidence > max.confidence ? current : max;
        });
    }

    function getMatchingWithMaxDistance(matchings: Matching[]): Matching | undefined {
        if (matchings.length === 0) {
            return undefined; // Return undefined if the array is empty
        }

        // Initialize the max matching as the first matching with a non-zero distance, if found
        let maxDistanceMatching: Matching | undefined = matchings[0].distance > 0 ? matchings[0] : undefined;

        for (const matching of matchings) {
            if (matching.distance > 0 && (!maxDistanceMatching || matching.distance > maxDistanceMatching.distance)) {
                maxDistanceMatching = matching;
            }
        }

        return maxDistanceMatching;
    }

    const handleSave = async () => {
        setLoading(true);
        const locationState = location.state as EditorState;
        const updatedLeg = structuredClone(locationState.leg);
        updatedLeg.route = waypoints;
        updatedLeg.tolerance = tolerance;
        await updateLeg(locationState.dayId, updatedLeg);
        setLoading(false);
        navigate("/");
    }

    return (
        <>
            {/* Full-page loading overlay */}
            {loading && (
                <Box
                    position="fixed"
                    top={0}
                    left={0}
                    width="100vw"
                    height="100vh"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bgcolor="rgba(255, 255, 255, 0.7)"
                    zIndex={9999}
                >
                    <CircularProgress />
                </Box>
            )}

            {/* Main content */}
            <div id='map-container' style={{ width: '100vw', height: '100vh', }} ref={mapContainerRef}/>
            <div style={{position: 'absolute', top: '16px', left: '16px'}}>
                <div style={{display: 'flex'}}>
                    <IconButton aria-label="dragdrop" sx={{
                        backgroundColor: 'white',
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
                    }} disableRipple onClick={() => {navigate("/")}}><ArrowBackIcon /></IconButton>
                    <div style={{
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        padding: '6px 16px 4px 16px',
                        marginLeft: '8px',
                        minWidth: '400px',
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
                    }}>
                        <Typography sx={{ textAlign: 'left' }} variant="subtitle1">{legTitle}</Typography>
                    </div>
                </div>
                {/* Map Legend Paper */}
                <Paper elevation={2} style={{ padding: '0px 12px 0px 16px', marginTop: '8px', width: '452px' }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography sx={{paddingTop: '6px'}} variant="subtitle1" gutterBottom>Legend</Typography>
                        <Box display="flex" alignItems="center" mr={2}>
                            <Box sx={{ width: '20px', height: '20px', backgroundColor: '#1976d2', marginRight: '8px' }} />
                            <Typography variant="body2">Imported KML/KMZ</Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Box sx={{ width: '20px', height: '20px', backgroundColor: '#F44336', marginRight: '8px' }} />
                            <Typography variant="body2">App Preview</Typography>
                        </Box>
                    </Box>
                </Paper>


                <Paper elevation={2} style={{ padding: '6px 8px 4px 16px', marginTop: '8px', width: '456px' }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" onClick={handleToggle} style={{ cursor: 'pointer' }}>
                        <Typography variant="subtitle1">Optimize App Route</Typography>
                        <IconButton size="small">
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>

                    <Collapse in={expanded}>
                        <Box mt={2} sx={{marginRight: '8px', marginBottom: '12px' }}>
                            <Typography variant="subtitle2" gutterBottom>
                                Tolerance ({tolerance})
                            </Typography>
                            <Box display="flex" alignItems="center">
                                <Typography variant="body2" style={{ marginRight: 8 }}>
                                    high
                                </Typography>
                                <Slider
                                    value={tolerance}
                                    min={50}
                                    max={2000}
                                    step={50}
                                    onChange={(_, newValue) => setTolerance(newValue)}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="tolerance-slider"
                                />
                                <Typography variant="body2" style={{ marginLeft: 8 }}>
                                    low
                                </Typography>
                            </Box>
                            <Typography variant="caption" display="block" style={{ marginTop: 8 }}>
                                Attention! Lower tolerance (more waypoints) requires additional API calls, which may lead to higher Mapbox costs. Recommendation is 600.
                            </Typography>
                            <Box display="flex" justifyContent="flex-end" marginTop="16px">
                                <Button sx={{marginRight: '8px'}} variant="text" color="primary" onClick={() => handleApply(tolerance)}>
                                    Apply
                                </Button>
                                <Button variant="contained" color="primary" disabled={appliedTolerance !== tolerance} onClick={handleSave}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Collapse>
                </Paper>
            </div>
        </>
    );
}

export default MapBoxEditor;